import React from "react";
import resume from "../assets/resume-eric-nunez.pdf";
import profilePic from "../assets/profilePictureEdit1.jpg";

function Introduction() {
  return (
    <div className="intro">
      <div className="container ">
        <div className="row shadow intro-cont">
          <section className="introLeft col-md-6">
            <div className="introPicture">
              <img className="shadow" src={profilePic} alt="" />
            </div>
            <div className="introTitle">
              <h1>Eric Nunez</h1>
              <h2>Software Developer</h2>
              <h4>nunezbareric@gmail.com</h4>
            </div>
          </section>
          <section className="introRight col-md-6">
            <p>
              Hello, my name is Eric Nunez and I am a software developer. I
              developed a love for coding from my very first CS class. I loved
              it so much and got pretty good at it that I was later chosen to do
              tutoring for other students. Tutoring in college was honestly some
              of the most fulfilling time I had while in school. I got my degree
              in computer science in the Summer of 2019.
            </p>
            <p>
              In my spare time I enjoy gardening, especially vegetables. I try
              not to use any chemicals as much as possible. It all becomes worth
              it when harvest time comes along and I get to share with my
              friends and family.
            </p>
            <p>
              Thank you for coming to my site, and I hope you take a look at
              some of the projects that i've worked on. Along with my projects
              you can also find my resume below.
            </p>
            <a className="btn btn-outline-primary" href={resume}>
              Resume
            </a>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
