import React from "react";
import calendar from "../assets/calendar.png";
import corona from "../assets/corona.png";
import colortrap from "../assets/colortrap.png";
import detection from "../assets/detection.png";
import roadtripper from "../assets/roadtripper.png";
import imagegallery from "../assets/imagegalleryscreenshot.png";

function Projects() {
  return (
    <div className="projects">
      <section className="cards m-3">
        <article className="card">
          <img className="card-img-top" src={roadtripper} alt="..." />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">Roadtripper</h5>
            <p className="card-text">
              A web application that allows users to create lists of their most
              essential items for any kind adventure like camping or a road
              trip. The application allows for the public view of lists created
              and allows for users to create, edit and add pictures to their
              created list.
            </p>
            <div className="card-btn">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-outline-primary mr-2"
                href="https://github.com/Ericnunez/traveler"
              >
                Code
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-outline-primary"
                href="https://roadtripper-fc6cc.web.app/home"
              >
                Live
              </a>
            </div>
          </div>
          <div className="card-footer"></div>
        </article>
        <article className="card">
          <img className="card-img-top" src={corona} alt="..." />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">Coronavirus tracker</h5>
            <p className="card-text">
              A coronavirus tracking application that tracks daily case
              statistics using the disease.sh open api. The application is built
              with react and implements a leaflet map that charts cumulative
              data for each country reporting cases.
            </p>
            <div className="card-btn mt-auto">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="btn mt-auto btn-outline-primary mr-2 "
                href="https://github.com/Ericnunez/covid"
              >
                Code
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="btn mt-auto btn-outline-primary"
                href="https://corona-tracker-9e5a6.web.app/"
              >
                Live
              </a>
            </div>
          </div>
          <div className="card-footer"></div>
        </article>

        <article className="card">
          <img className="card-img-top" src={calendar} alt="..." />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">Gui Calendar</h5>
            <p className="card-text">
              This was a gui final project for event driven programming. The
              calendar is based on the gregorian calendar and allows users to
              save appointments.
            </p>
            <div className="card-btn mt-auto">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-outline-primary mr-2"
                href="https://github.com/Ericnunez/Calendar"
              >
                Code
              </a>
            </div>
          </div>
          <div className="card-footer"></div>
        </article>

        <article className="card">
          <img className="card-img-top" src={imagegallery} alt="..." />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">Image Gallery</h5>
            <p className="card-text">
              Image gallery is a image file viewer made to function similarly to
              preview on MacOS.
            </p>
            <div className="card-btn mt-auto">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-outline-primary mr-2"
                href="https://github.com/Ericnunez/image-gallery"
              >
                Code
              </a>
            </div>
          </div>
          <div className="card-footer"></div>
        </article>
        <article className="card">
          <img className="card-img-top" src={detection} alt="..." />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">DetectionAI</h5>
            <p className="card-text">
              This is a GUI application that works with your webcam to be able
              to detect faces, eyes and smiles. This is written using Java 14,
              the openCV library and javaFX 14.
            </p>
            <div className="card-btn mt-auto">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-outline-primary mr-2"
                href="https://github.com/Ericnunez/DetectionAI"
              >
                Code
              </a>
            </div>
          </div>
          <div className="card-footer"></div>
        </article>
        <article className="card">
          <img className="card-img-top" src={colortrap} alt="..." />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">Color Trap game</h5>
            <p className="card-text">
              Color Trap is a game where a user has to identify the color of the
              main word and match it with the corresponding color name.
            </p>
            <div className="card-btn mt-auto">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-outline-primary mr-2"
                href="https://github.com/Ericnunez/Color-Trap"
              >
                Code
              </a>
            </div>
          </div>
          <div className="card-footer"></div>
        </article>
      </section>
    </div>
  );
}

export default Projects;
