import React from "react";
import "./appStyles.css";
import { Switch, Route } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Projects from "./components/Projects";
import Introduction from "./components/Introduction";

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <div className="stripes">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Switch>
        <Route path="/projects" component={Projects}></Route>
        <Route path="/" component={Introduction}></Route>
      </Switch>
    </div>
  );
}

export default App;
